<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Reportes</span>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-row><v-col> </v-col></v-row>
    <v-row>
      <v-col md="12">
        <v-list two-line>
          <v-list-item
            v-for="reporte in reportes_list"
            :key="reporte.title"
            link
            :to="{ name: reporte.link_name }"
          >
            <v-list-item-avatar>
              <v-icon class="grey lighten-1" dark> mdi-file-chart </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ reporte.title }}</v-list-item-title>

              <v-list-item-subtitle>{{
                reporte.subtitle
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Reportes",
  components: {},
  data() {
    return {
      loading: false,
      reportes_list: [
        {
          title: "Turnos Semanales",
          subtitle: "Turnos semanales por empleado",
          link_name: "ReporteTurnosSemanales",
          icon: "mdi-view-dashboard",
        },
        // {
        //   title: "Novedades",
        //   subtitle: "Lista las novedades por empleado",
        //   link_name: "Inicio",
        //   icon: "mdi-newspaper",
        // },
      ],
    };
  },
  methods: {},
};
</script>
